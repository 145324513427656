// librairies
import {
    Map,
    ScaleControl,
    NavigationControl,
    Source,
    Layer,
} from "react-map-gl";
import { useEffect, useState } from "react";
import CadastreServices from "../services/cadastre";
import { useAuth0 } from "@auth0/auth0-react";
import * as turf from "@turf/turf";

// CSS Imports
import "mapbox-gl/dist/mapbox-gl.css";
import layerStyle from "../style/layer-style";

import Measure from "./measure";
import CatchmentArea from "./catchment-area";

// data
import departementsData from "../assets/geojson/departements.json";
import HoverInfo from "./hover-info";
import { useGlobalState } from "../pages/app-container";
import CollectionServices from "../services/collections";
import LayerControl from "./layer-control";
import ContextMenu from "./context-menu";
import monumentIcon from "../assets/images/monument.png";
import padelIcon from "../assets/images/padel.png";
import PEB from "../assets/geojson/peb.json";
import padelsData from "../assets/geojson/padels.json";

const initialViewState = {
    longitude: 1.4434618432873223,
    latitude: 43.61929716198888,
    zoom: 5,
};

function Mapbox({ mapRef }) {
    let pathname = window.location.pathname;

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useGlobalState("token");

    // switch show/hide sources
    const [showDepartements, setShowDepartements] = useState(true);
    const [showCommunes, setShowCommunes] = useState(false);
    const [showSections, setShowSections] = useState(false);
    const [showParcelles, setShowParcelles] = useState(false);
    const [showPEB, setShowPEB] = useState(false);
    const [showPadels, setShowPadels] = useState(false);
    const [showPLU, setShowPLU] = useState(false);
    const [showPresSurf, setShowPresSurf] = useState(false);
    const [showPresLin, setShowPresLin] = useState(false);
    const [showPresPct, setShowPresPct] = useState(false);
    const [showMonHist, setShowMonHist] = useState(false);
    const [showPPR, setShowPPR] = useState(false);

    // sources data
    const [communesData, setCommunesData] = useState(null);
    const [sectionData, setSectionData] = useState(null);
    const [parcellesData, setParcellesData] = useState(null);
    const [buildingsData, setBuildingsData] = useState(null);

    const [pluData, setPluData] = useState(null);
    const [presSurfData, setPresSurfData] = useState(null);
    const [presLinData, setPresLinData] = useState(null);
    const [presPctData, setPresPctData] = useState(null);
    const [monHistData, setMonHistData] = useState(null);
    const [pprData, setPprData] = useState(null);

    const [rulerData, setRulerData] = useState(null);

    // utilitaire
    const [lastDepartement, setLastDepartement] = useState(null);
    const [lastCommune, setLastCommune] = useState(null);
    const [contextMenuState, setContextMenuState] = useState({
        visible: false,
        pos: { x: 0, y: 0 },
        parcelle: null,
        lngLat: null,
        address: null,
    });

    const [fetchingPLU, setFetchingPLU] = useState(false);
    const [fetchingPresSurf, setFetchingPresSurf] = useState(false);
    const [fetchingPresLin, setFetchingPresLin] = useState(false);
    const [fetchingPresPct, setFetchingPresPct] = useState(false);
    const [fetchingMonHist, setFetchingMonHist] = useState(false);
    const [fetchingPPR, setFetchingPPR] = useState(false);

    const [opacityPLU, setOpacityPLU] = useState(0.1);
    const [opacityPresSurf, setOpacityPresSurf] = useState(0.3);
    const [opacityPPR, setOpacityPPR] = useState(0.3);
    const [opacityPEB, setOpacityPEB] = useState(0.25);

    const [isRulerActive, setIsRulerActive] = useState(false);
    const [isCatchmentAreaActive, setIsCatchmentAreaActive] = useState(false);

    let singleClickTimeOut = null;

    // hover
    const [hoveredFeature, setHoveredFeature] = useState(null);
    const [hoveredSource, setHoveredSource] = useState(null);
    const [hoverInfo, setHoverInfo] = useState(null);

    useEffect(() => {
        if (token === null) {
            getAccessTokenSilently().then((token) => {
                setToken(token);
            });
        }

        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);
    });

    // global state
    const [openedCollection, setOpenedCollection] =
        useGlobalState("openedCollection");
    const [searchCollection, setSearchCollection] =
        useGlobalState("searchCollection");
    const [rulerLine] = useGlobalState("rulerLine");

    const fetchAddress = async (lngLat) => {
        const res = await fetch(
            `https://api.mapbox.com/search/geocode/v6/reverse?longitude=` +
                lngLat.lng +
                `&latitude=` +
                lngLat.lat +
                `&types=address&access_token=pk.eyJ1Ijoicm11bnRkcyIsImEiOiJjbGRucGtxZTgwazBpM3BxZzdpOXBsdTlkIn0.Ixv9Rh6eRAgrN0hkYWi8KA`
        );
        const data = await res.json();

        if (data.features[0].properties.full_address) {
            return data.features[0].properties.full_address;
        } else {
            return null;
        }
    };

    const onMapMove = (e) => {
        setContextMenuState({
            visible: false,
            pos: { x: 0, y: 0 },
            parcelle: null,
        });
        let zoomLevel = mapRef.current.getZoom();
        let dpt = CadastreServices.getDepartementsByCoords(
            e.viewState.longitude,
            e.viewState.latitude
        );

        if (zoomLevel > 10 && zoomLevel <= 13) {
            if (lastDepartement !== dpt) {
                // changement de département, on recharge les communes
                CadastreServices.getCommunesByDepartement(dpt).then((data) => {
                    if (data !== null) setCommunesData(data);
                });
                setShowPLU(false);
                setShowPresSurf(false);
                setShowPresLin(false);
                setShowPresPct(false);
            }
        } else if (zoomLevel > 13 && zoomLevel <= 16) {
            CadastreServices.getDataByCoord(
                e.viewState.latitude,
                e.viewState.longitude
            ).then((currentPos) => {
                if (currentPos != null && currentPos.citycode !== null) {
                    if (lastCommune !== currentPos.citycode) {
                        // changement de commune, téléchargement de la section
                        CadastreServices.getSectionsByInsee(
                            currentPos.citycode
                        ).then((response) => {
                            if (response !== null) setSectionData(response);
                        });
                        setLastCommune(currentPos.citycode);

                        setShowPLU(false);
                        setShowPresSurf(false);
                        setShowPresLin(false);
                        setShowPresPct(false);
                    }
                }
            });
        } else if (zoomLevel > 16) {
            CadastreServices.getDataByCoord(
                e.viewState.latitude,
                e.viewState.longitude
            ).then((currentPos) => {
                if (currentPos != null && currentPos.citycode !== null) {
                    if (lastCommune !== currentPos.citycode) {
                        // changement de commune, téléchargement de la section
                        CadastreServices.getSectionsByInsee(
                            currentPos.citycode
                        ).then((response) => {
                            if (response !== null) setSectionData(response);
                        });
                        setLastCommune(currentPos.citycode);

                        // récupération et affichage des parcelles
                        CadastreServices.getPlotsByInsee(
                            currentPos.citycode
                        ).then((response) => {
                            if (response) {
                                setParcellesData(response);
                                setShowParcelles(true);
                            }
                        });

                        // récupération des batiments
                        CadastreServices.getBatiByInsee(
                            currentPos.citycode
                        ).then((response) => {
                            if (response) {
                                setBuildingsData(response);
                            }
                        });

                        setShowPLU(false);
                        setShowPresSurf(false);
                        setShowPresLin(false);
                        setShowPresPct(false);

                        // récupération des PLU
                        setFetchingPLU("waiting " + currentPos.citycode);
                        CadastreServices.getPLUByInsee(
                            currentPos.citycode
                        ).then((response) => {
                            if (response && response.features.length > 0) {
                                setPluData(response);
                                setFetchingPLU("done " + currentPos.citycode);
                            } else {
                                setFetchingPLU("none " + currentPos.citycode);
                            }
                        });
                    }
                }
            });
        }

        setLastDepartement(dpt);
    };

    const onMapZoom = (e) => {
        setContextMenuState({
            visible: false,
            pos: { x: 0, y: 0 },
            parcelle: null,
        });
        let dpt = CadastreServices.getDepartementsByCoords(
            e.viewState.longitude,
            e.viewState.latitude
        );
        setLastDepartement(dpt);

        if (e.viewState.zoom < 10) {
            // Affichage des départements
            setShowDepartements(true);

            // suppression des communes
            if (showCommunes) setShowCommunes(false);

            // suppression des hovers
            setHoveredFeature(null);
            setHoveredSource(null);
        } else {
            // Supression des départements
            if (showDepartements) setShowDepartements(false);

            // Affichage des communes
            setShowCommunes(true);
            if (
                lastDepartement !== dpt ||
                communesData === null ||
                (communesData.features &&
                    communesData.features[0] !== undefined &&
                    communesData.features[0].properties.id.substring(0, 2) !==
                        dpt)
            ) {
                // changement de département, on recharge les communes
                CadastreServices.getCommunesByDepartement(dpt).then((data) => {
                    if (data !== null) setCommunesData(data);
                });
            }

            if (e.viewState.zoom <= 13) {
                // suppression des hovers
                setHoveredFeature(null);
                setHoveredSource(null);

                if (showSections) setShowSections(false);
                if (showParcelles) setShowParcelles(false);
                if (showPLU) setShowPLU(false);
            } else if (e.viewState.zoom > 13 && e.viewState.zoom <= 16) {
                CadastreServices.getDataByCoord(
                    e.viewState.latitude,
                    e.viewState.longitude
                ).then((currentPos) => {
                    if (currentPos != null && currentPos.citycode !== null) {
                        // meme département, on vérifie si la commune a changé
                        if (lastCommune !== currentPos.citycode) {
                            // changement de commune, téléchargement de la section
                            CadastreServices.getSectionsByInsee(
                                currentPos.citycode
                            ).then((response) => {
                                if (response !== null) setSectionData(response);
                            });
                            setLastCommune(currentPos.citycode);
                        }
                    }
                });

                if (!showSections) setShowSections(true);
            } else if (e.viewState.zoom > 16) {
                CadastreServices.getDataByCoord(
                    e.viewState.latitude,
                    e.viewState.longitude
                ).then((currentPos) => {
                    if (currentPos != null && currentPos.citycode !== null) {
                        setLastCommune(currentPos.citycode);

                        // meme département, on vérifie si la commune a changé
                        if (
                            lastCommune !== currentPos.citycode ||
                            parcellesData === null ||
                            (parcellesData.features &&
                                parcellesData.features[0] !== undefined &&
                                parcellesData.features[0].properties.commune !==
                                    currentPos.citycode)
                        ) {
                            // changement de commune, téléchargement de la section
                            CadastreServices.getSectionsByInsee(
                                currentPos.citycode
                            ).then((response) => {
                                if (response !== null) setSectionData(response);
                                setShowSections(true);
                            });

                            // récupération et affichage des parcelles
                            CadastreServices.getPlotsByInsee(
                                currentPos.citycode
                            ).then((response) => {
                                if (response) {
                                    setParcellesData(response);
                                    setShowParcelles(true);
                                }
                            });

                            // récupération des batiments
                            CadastreServices.getBatiByInsee(
                                currentPos.citycode
                            ).then((response) => {
                                if (response) {
                                    setBuildingsData(response);
                                    // console.log(response);
                                }
                            });

                            setFetchingPLU("waiting " + currentPos.citycode);
                            CadastreServices.getPLUByInsee(
                                currentPos.citycode
                            ).then((response) => {
                                if (response && response.features.length > 0) {
                                    setPluData(response);
                                    setFetchingPLU(
                                        "done " + currentPos.citycode
                                    );
                                } else {
                                    setFetchingPLU(
                                        "none " + currentPos.citycode
                                    );
                                }
                            });
                        }
                    }
                });

                // Affichage des parcelles
                if (!showParcelles) setShowParcelles(true);
            }
        }
    };

    const onMouseMove = (e) => {
        let zoomLevel = mapRef.current.getZoom();

        if (hoveredFeature !== null && hoveredSource !== null) {
            mapRef.current.setFeatureState(
                {
                    source: hoveredSource,
                    id: hoveredFeature,
                },
                {
                    hover: false,
                }
            );
        }

        setHoverInfo(null);

        if (isRulerActive && rulerData !== null) {
            setHoverInfo({
                distance: rulerData.distance,
                area: rulerData.area,
                point: e.point,
            });
        } else {
            if (mapRef.current.getMap().style._order.includes("padelsSymbol")) {
                const feature = mapRef.current
                    .getMap()
                    .queryRenderedFeatures(e.point, {
                        layers: ["padelsSymbol"],
                    })[0];
                if (feature) {
                    setHoverInfo({
                        point: e.point,
                        indoor: feature.properties.indoor,
                        outdoor: feature.properties.outdoor,
                        total: feature.properties.total,
                        structure: feature.properties.structure,
                        visibility: feature.properties.visibility,
                    });
                }
            }

            if (zoomLevel > 13 && zoomLevel <= 16) {
                if (
                    mapRef.current
                        .getMap()
                        .style._order.includes("sections-fill")
                ) {
                    if (
                        showPLU &&
                        pluData !== null &&
                        pluData.features.length > 0
                    ) {
                        const feature = mapRef.current
                            .getMap()
                            .queryRenderedFeatures(e.point, {
                                layers: ["pluFill"],
                            })[0];
                        if (feature) {
                            setHoveredFeature(feature.id);
                            setHoveredSource(feature.source);

                            mapRef.current.setFeatureState(
                                {
                                    source: feature.source,
                                    id: feature.id,
                                },
                                {
                                    hover: true,
                                }
                            );

                            setHoverInfo(
                                {
                                    name: feature.properties.libelle,
                                    libelle: feature.properties.libelong,
                                    point: e.point,
                                },
                                "PLU"
                            );
                        }
                    } else {
                        let feature = undefined;
                        if (
                            mapRef.current
                                .getMap()
                                .style._order.includes("sections-label")
                        ) {
                            feature = mapRef.current
                                .getMap()
                                .queryRenderedFeatures(e.point, {
                                    layers: ["sections-fill", "sections-label"],
                                })[0];
                        } else {
                            feature = mapRef.current
                                .getMap()
                                .queryRenderedFeatures(e.point, {
                                    layers: ["sections-fill"],
                                })[0];
                        }
                        if (feature) {
                            setHoveredFeature(feature.id);
                            setHoveredSource(feature.source);

                            mapRef.current.setFeatureState(
                                {
                                    source: feature.source,
                                    id: feature.id,
                                },
                                {
                                    hover: true,
                                }
                            );
                        }
                    }
                }
            } else if (zoomLevel > 16) {
                if (
                    mapRef.current
                        .getMap()
                        .style._order.includes("parcelles-fill")
                ) {
                    const feature = mapRef.current
                        .getMap()
                        .queryRenderedFeatures(e.point, {
                            layers: ["parcelles-fill", "parcelles-label"],
                        })[0];

                    let zonePLU = undefined;

                    if (
                        mapRef.current.getMap().style._order.includes("pluFill")
                    )
                        zonePLU = mapRef.current
                            .getMap()
                            .queryRenderedFeatures(e.point, {
                                layers: ["pluFill"],
                            })[0];

                    if (feature) {
                        setHoveredFeature(feature.id);
                        setHoveredSource(feature.source);

                        mapRef.current.setFeatureState(
                            {
                                source: feature.source,
                                id: feature.id,
                            },
                            {
                                hover: true,
                            }
                        );

                        setHoverInfo({
                            name:
                                (feature.properties.prefixe === "000"
                                    ? ""
                                    : feature.properties.prefixe + " ") +
                                (feature.properties.section.length === 1
                                    ? "0"
                                    : "") +
                                feature.properties.section +
                                " " +
                                feature.properties.numero,
                            contenance: feature.properties.contenance,
                            point: e.point,
                            plu: zonePLU
                                ? {
                                      libelle: zonePLU.properties.libelong,
                                      zone: zonePLU.properties.libelle,
                                  }
                                : null,
                        });
                    }
                }
            }
        }
    };

    const onMouseLeave = () => {
        if (hoveredFeature !== null) {
            mapRef.current.setFeatureState(
                {
                    source: hoveredSource,
                    id: hoveredFeature,
                },
                {
                    hover: false,
                }
            );
        }
    };

    const handleContextMenuClick = async (e) => {
        e.preventDefault();
        let zoomLevel = mapRef.current.getZoom();
        if (
            zoomLevel > 16 &&
            pathname !== "/collections/new" &&
            !isRulerActive
        ) {
            const parcelle = mapRef.current
                .getMap()
                .queryRenderedFeatures(e.point, {
                    layers: ["parcelles-fill", "parcelles-label"],
                })[0];
            const address = await fetchAddress(e.lngLat);

            if (parcelle !== undefined) {
                setContextMenuState({
                    visible: true,
                    pos: { x: e.point.x, y: e.point.y },
                    parcelle: parcelle,
                    lngLat: e.lngLat,
                    address: address,
                });
            }
        }
    };

    const handleDblClick = (e) => {
        clearTimeout(singleClickTimeOut);
        e.preventDefault();
    };

    const handleClick = (e) => {
        setContextMenuState({
            visible: false,
            pos: { x: 0, y: 0 },
            parcelle: null,
        });
        const timeoutId = setTimeout(() => {
            if (timeoutId === singleClickTimeOut) {
                if (!isRulerActive) {
                    let zoomLevel = mapRef.current.getZoom();
                    if (zoomLevel > 16) {
                        //const collectionFeature = mapRef.current.getMap().queryRenderedFeatures(e.point, { layers: ["currentCollectionParcellesFill", "currentCollectionParcellesLine"] })[0];
                        const feature = mapRef.current
                            .getMap()
                            .queryRenderedFeatures(e.point, {
                                layers: ["parcelles-fill", "parcelles-label"],
                            })[0];

                        if (pathname === "/collections/new") {
                            if (searchCollection !== null) {
                                // searchCollection non vide, on est dans une recherche
                                const searchFeature = mapRef.current
                                    .getMap()
                                    .queryRenderedFeatures(e.point, {
                                        layers: [
                                            "searchCollectionParcellesFill",
                                            "searchCollectionParcellesLine",
                                        ],
                                    })[0];
                                const keepFeature = mapRef.current
                                    .getMap()
                                    .queryRenderedFeatures(e.point, {
                                        layers: [
                                            "keepCollectionParcellesFill",
                                            "keepCollectionParcellesLine",
                                        ],
                                    })[0];

                                if (searchFeature !== undefined) {
                                    if (keepFeature !== undefined) {
                                        // clic sur une parcelle avec accepted = true
                                        setSearchCollection({
                                            ...searchCollection,
                                            plots: searchCollection.plots.map(
                                                (plot) =>
                                                    plot.id ===
                                                    keepFeature.properties.id
                                                        ? {
                                                              ...plot,
                                                              accepted: false,
                                                          }
                                                        : plot
                                            ),
                                        });
                                    } else {
                                        // clic sur une parcelle avec accepted = false
                                        setSearchCollection({
                                            ...searchCollection,
                                            plots: searchCollection.plots.map(
                                                (plot) =>
                                                    plot.id ===
                                                    searchFeature.properties.id
                                                        ? {
                                                              ...plot,
                                                              accepted: true,
                                                          }
                                                        : plot
                                            ),
                                        });
                                    }
                                } else {
                                    if (keepFeature !== undefined) {
                                        // retrait d'une parcelle en dehors de la recherche
                                        setSearchCollection({
                                            ...searchCollection,
                                            plots: searchCollection.plots.filter(
                                                (plot) =>
                                                    plot.id !==
                                                    keepFeature.properties.id
                                            ),
                                        });
                                    } else {
                                        // ajout d'une parcelle en dehors de la recherche
                                        let plot = {
                                            id: feature.properties.id,
                                            fav: 0,
                                            memo: "",
                                            numero: feature.properties.numero,
                                            arpente: feature.properties.arpente,
                                            prefixe: feature.properties.prefixe,
                                            section: feature.properties.section,
                                            code_insee:
                                                feature.properties.commune,
                                            contenance:
                                                feature.properties.contenance,
                                            creation_date: new Date()
                                                .toISOString()
                                                .slice(0, 19)
                                                .replace("T", " "),
                                            plot_geometry: feature.geometry,
                                            buildings_geometry: [],
                                            bbox: turf.bbox(feature),
                                            commune: sectionData.features.find(
                                                (section) =>
                                                    section.properties
                                                        .section ===
                                                    (feature.properties.section
                                                        .length === 1
                                                        ? "0" +
                                                          feature.properties
                                                              .section
                                                        : feature.properties
                                                              .section)
                                            ).properties.nom_com,
                                            accepted: true,
                                        };

                                        if (
                                            fetchingPLU ===
                                            "done " + lastCommune
                                        ) {
                                            let zones = [];
                                            pluData.features.forEach((zone) => {
                                                let isInside = false;
                                                plot.plot_geometry.coordinates[0].forEach(
                                                    (point) => {
                                                        if (
                                                            turf.booleanPointInPolygon(
                                                                turf.point(
                                                                    point
                                                                ),
                                                                zone
                                                            )
                                                        ) {
                                                            isInside = true;
                                                        }
                                                    }
                                                );
                                                if (isInside)
                                                    zones.push({
                                                        label: zone.properties.libelong.replaceAll(
                                                            "'",
                                                            " "
                                                        ),
                                                        typezone:
                                                            zone.properties
                                                                .typezone,
                                                        zone: zone.properties
                                                            .libelle,
                                                    });
                                            });

                                            plot.zones = zones;
                                        } else if (
                                            fetchingPLU ===
                                            "waiting " + lastCommune
                                        ) {
                                            alert(
                                                "Veuillez attendre que les données PLU soient chargées"
                                            );
                                            return;
                                        } else if (
                                            fetchingPLU ===
                                            "none " + lastCommune
                                        ) {
                                            plot.zones = [];
                                        }

                                        setSearchCollection({
                                            ...searchCollection,
                                            plots: [
                                                ...searchCollection.plots,
                                                plot,
                                            ],
                                        });
                                    }
                                }
                            } else {
                                // searchCollection vide, ajout d'une parcelle à la collection avec accepted = true
                                // ajout d'une parcelle en dehors de la recherche
                                let plot = {
                                    id: feature.properties.id,
                                    fav: 0,
                                    memo: "",
                                    numero: feature.properties.numero,
                                    arpente: feature.properties.arpente,
                                    prefixe: feature.properties.prefixe,
                                    section: feature.properties.section,
                                    code_insee: feature.properties.commune,
                                    contenance: feature.properties.contenance,
                                    creation_date: new Date()
                                        .toISOString()
                                        .slice(0, 19)
                                        .replace("T", " "),
                                    plot_geometry: feature.geometry,
                                    buildings_geometry: [],
                                    bbox: turf.bbox(feature),
                                    commune: sectionData.features.find(
                                        (section) =>
                                            section.properties.section ===
                                            feature.properties.section
                                    ).properties.nom_com,
                                    accepted: true,
                                };

                                if (fetchingPLU === "done " + lastCommune) {
                                    let zones = [];
                                    pluData.features.forEach((zone) => {
                                        let isInside = false;
                                        plot.plot_geometry.coordinates[0].forEach(
                                            (point) => {
                                                if (
                                                    turf.booleanPointInPolygon(
                                                        turf.point(point),
                                                        zone
                                                    )
                                                ) {
                                                    isInside = true;
                                                }
                                            }
                                        );
                                        if (isInside)
                                            zones.push({
                                                label: zone.properties.libelong.replaceAll(
                                                    "'",
                                                    " "
                                                ),
                                                typezone:
                                                    zone.properties.typezone,
                                                zone: zone.properties.libelle,
                                            });
                                    });

                                    plot.zones = zones;
                                } else if (
                                    fetchingPLU ===
                                    "waiting " + lastCommune
                                ) {
                                    while (
                                        fetchingPLU ===
                                        "waiting " + lastCommune
                                    ) {}
                                    if (fetchingPLU === "done " + lastCommune) {
                                        let zones = [];
                                        pluData.features.forEach((zone) => {
                                            let isInside = false;
                                            plot.plot_geometry.coordinates[0].forEach(
                                                (point) => {
                                                    if (
                                                        turf.booleanPointInPolygon(
                                                            turf.point(point),
                                                            zone
                                                        )
                                                    ) {
                                                        isInside = true;
                                                    }
                                                }
                                            );
                                            if (isInside)
                                                zones.push({
                                                    label: zone.properties.libelong.replaceAll(
                                                        "'",
                                                        " "
                                                    ),
                                                    typezone:
                                                        zone.properties
                                                            .typezone,
                                                    zone: zone.properties
                                                        .libelle,
                                                });
                                        });

                                        plot.zones = zones;
                                    } else if (
                                        fetchingPLU ===
                                        "none " + lastCommune
                                    ) {
                                        plot.zones = [];
                                    }
                                } else if (
                                    fetchingPLU ===
                                    "none " + lastCommune
                                ) {
                                    plot.zones = [];
                                }

                                setSearchCollection({ plots: [plot] });
                            }
                        } else if (openedCollection !== null) {
                            const collectionFeature =
                                mapRef.current.queryRenderedFeatures(e.point, {
                                    layers: [
                                        "currentCollectionParcellesLine",
                                        "currentCollectionParcellesFill",
                                    ],
                                })[0];

                            if (collectionFeature !== undefined) {
                                // retrait de la feature de la collection ouverte
                                CollectionServices.updateCollection(
                                    {
                                        ...openedCollection,
                                        plots: openedCollection.plots.filter(
                                            (plot) =>
                                                plot.id !==
                                                collectionFeature.properties.id
                                        ),
                                    },
                                    token
                                ).then((res) => {
                                    if (res.status === 200) {
                                        setOpenedCollection({
                                            ...openedCollection,
                                            plots: openedCollection.plots.filter(
                                                (plot) =>
                                                    plot.id !==
                                                    collectionFeature.properties
                                                        .id
                                            ),
                                        });
                                    } else {
                                        // TODO : afficher un message d'erreur
                                    }
                                });
                            } else {
                                // ajout de la feature à la collection ouverte
                                let plot = {
                                    id: feature.properties.id,
                                    fav: 0,
                                    memo: "",
                                    numero: feature.properties.numero,
                                    arpente: feature.properties.arpente,
                                    prefixe: feature.properties.prefixe,
                                    section: feature.properties.section,
                                    code_insee: feature.properties.commune,
                                    contenance: feature.properties.contenance,
                                    creation_date: new Date()
                                        .toISOString()
                                        .slice(0, 19)
                                        .replace("T", " "),
                                    plot_geometry: feature.geometry,
                                    buildings_geometry: [],
                                    bbox: turf.bbox(feature),
                                    commune: sectionData.features.find(
                                        (section) =>
                                            section.properties.section ===
                                            feature.properties.section
                                    ).properties.nom_com,
                                    accepted: true,
                                };

                                if (fetchingPLU === "done " + lastCommune) {
                                    let zones = [];
                                    pluData.features.forEach((zone) => {
                                        let isInside = false;
                                        plot.plot_geometry.coordinates[0].forEach(
                                            (point) => {
                                                if (
                                                    turf.booleanPointInPolygon(
                                                        turf.point(point),
                                                        zone
                                                    )
                                                ) {
                                                    isInside = true;
                                                }
                                            }
                                        );
                                        if (isInside)
                                            zones.push({
                                                label: zone.properties.libelong.replaceAll(
                                                    "'",
                                                    " "
                                                ),
                                                typezone:
                                                    zone.properties.typezone,
                                                zone: zone.properties.libelle,
                                            });
                                    });

                                    plot.zones = zones;
                                } else if (
                                    fetchingPLU ===
                                    "waiting " + lastCommune
                                ) {
                                    console.log(
                                        "Veuillez attendre que les données PLU soient chargées"
                                    );
                                    return;
                                } else if (
                                    fetchingPLU ===
                                    "none " + lastCommune
                                ) {
                                    plot.zones = [];
                                    console.log(
                                        "Aucune donnée PLU n'est disponible pour cette commune"
                                    );
                                }
                                CollectionServices.updateCollection(
                                    {
                                        ...openedCollection,
                                        plots: [
                                            ...openedCollection.plots,
                                            plot,
                                        ],
                                    },
                                    token
                                ).then((res) => {
                                    if (res.status === 200) {
                                        setOpenedCollection({
                                            ...openedCollection,
                                            plots: [
                                                ...openedCollection.plots,
                                                plot,
                                            ],
                                        });
                                    } else {
                                        // TODO : afficher un message d'erreur
                                    }
                                });
                            }
                        } else if (
                            openedCollection === null &&
                            pathname !== "/collections/new"
                        ) {
                        }
                    }
                }
            }
        }, 300);
        singleClickTimeOut = timeoutId;
    };

    const handleKeyDown = (e) => {
        if (e.key === "Escape") {
            setContextMenuState({
                visible: false,
                pos: { x: 0, y: 0 },
                parcelle: null,
            });
        }
    };

    const handleKeyUp = (e) => {
        if (e.key === "Control") {
            mapRef.current.getCanvas().style.cursor = "grab";
        }
    };

    const handleLayerChange = (layerId, opacity) => {
        setContextMenuState({
            visible: false,
            pos: { x: 0, y: 0 },
            parcelle: null,
        });
        if (layerId === "PLU") {
            setOpacityPLU(opacity);
            if (pluData === null || pluData.insee !== lastCommune) {
                setFetchingPLU("waiting " + lastCommune);
                CadastreServices.getPLUByInsee(lastCommune).then((response) => {
                    if (response) {
                        setPluData(response);
                        if (response.features.length > 0) {
                            setFetchingPLU("done " + lastCommune);
                            setShowPLU(true);
                        } else {
                            setFetchingPLU("none " + lastCommune);
                        }
                    }
                });
            }
            setShowPLU(!showPLU);
        } else if (layerId === "pres-surf") {
            setOpacityPresSurf(opacity);
            if (presSurfData === null || presSurfData.insee !== lastCommune) {
                setFetchingPresSurf("waiting " + lastCommune);
                CadastreServices.getPrescriptionSurfByInsee(lastCommune).then(
                    (response) => {
                        if (response) {
                            setPresSurfData(response);
                            if (response.features.length > 0) {
                                setFetchingPresSurf("done " + lastCommune);
                                setShowPresSurf(true);
                            } else {
                                setFetchingPresSurf("none " + lastCommune);
                            }
                        }
                    }
                );
            }
            setShowPresSurf(!showPresSurf);
        } else if (layerId === "pres-lin") {
            if (presLinData === null || presLinData.insee !== lastCommune) {
                setFetchingPresLin("waiting " + lastCommune);
                CadastreServices.getPrescriptionLinByInsee(lastCommune).then(
                    (response) => {
                        if (response) {
                            setPresLinData(response);
                            if (response.features.length > 0) {
                                setFetchingPresLin("done " + lastCommune);
                                setShowPresLin(true);
                            } else {
                                setFetchingPresLin("none " + lastCommune);
                            }
                        }
                    }
                );
            }
            setShowPresLin(!showPresLin);
        } else if (layerId === "pres-pct") {
            if (presPctData === null || presPctData.insee !== lastCommune) {
                setFetchingPresPct("waiting " + lastCommune);
                CadastreServices.getPrescriptionPctByInsee(lastCommune).then(
                    (response) => {
                        if (response) {
                            setPresPctData(response);
                            if (response.features.length > 0) {
                                setFetchingPresPct("done " + lastCommune);
                                setShowPresPct(true);
                            } else {
                                setFetchingPresPct("none " + lastCommune);
                            }
                        }
                    }
                );
            }
            setShowPresPct(!showPresPct);
        } else if (layerId === "PPR") {
            setOpacityPPR(opacity);
            if (pprData === null || pprData.insee !== lastCommune) {
                setFetchingPPR("waiting " + lastCommune);
                CadastreServices.getPPRByInsee(lastCommune).then((response) => {
                    if (response) {
                        setPprData(response);
                        if (response.PPR.length > 0) {
                            setFetchingPPR("done " + lastCommune);
                            setShowPPR(true);
                        } else {
                            setFetchingPPR("none " + lastCommune);
                        }
                    }
                });
            }
            setShowPPR(!showPPR);
        } else if (layerId === "mon-hist") {
            if (monHistData === null || monHistData.insee !== lastCommune) {
                setFetchingMonHist("waiting " + lastCommune);
                CadastreServices.getMonumentHistoriquesByInsee(
                    lastCommune
                ).then((response) => {
                    if (response) {
                        setMonHistData(response);
                        if (response.monHist.length > 0) {
                            setFetchingMonHist("done " + lastCommune);
                            setShowMonHist(true);
                        } else {
                            setFetchingMonHist("none " + lastCommune);
                        }
                    }
                });
            }
            setShowMonHist(!showMonHist);
        } else if (layerId === "PEB") {
            setOpacityPEB(opacity);
            setShowPEB(!showPEB);
        } else if (layerId === "padels") {
            setShowPadels(!showPadels);
        }
    };

    const handleContextClose = () => {
        setContextMenuState({
            visible: false,
            pos: { x: 0, y: 0 },
            parcelle: null,
        });
    };

    const onMapLoad = (e) => {
        e.target.loadImage(monumentIcon, (error, image) => {
            if (error) throw error;
            e.target.addImage("monument", image);
        });

        e.target.loadImage(padelIcon, (error, image) => {
            if (error) throw error;
            e.target.addImage("padel", image);
        });
    };

    if (isAuthenticated) {
        return (
            <Map
                id="map"
                ref={mapRef}
                onLoad={onMapLoad}
                onMouseMove={onMouseMove}
                onClick={handleClick}
                onDblClick={handleDblClick}
                onZoomEnd={onMapZoom}
                onMoveEnd={onMapMove}
                onMouseOut={onMouseLeave}
                onContextMenu={handleContextMenuClick}
                doubleClickZoom={false}
                initialViewState={initialViewState}
                mapStyle="mapbox://styles/mapbox/satellite-streets-v9"
                style={{
                    position: "absolute",
                    width: "100vw",
                    height: "100vh",
                    top: 0,
                    zIndex: -1,
                }}
                mapboxAccessToken="pk.eyJ1Ijoicm11bnRkcyIsImEiOiJjbGRucGtxZTgwazBpM3BxZzdpOXBsdTlkIn0.Ixv9Rh6eRAgrN0hkYWi8KA"
            >
                {showDepartements && (
                    <Source
                        id="departements"
                        type="geojson"
                        data={departementsData}
                        tolerance={1}
                    >
                        <Layer {...layerStyle.departements} />
                    </Source>
                )}

                {showCommunes && (
                    <Source
                        id="communes"
                        type="geojson"
                        data={communesData}
                        tolerance={3}
                    >
                        <Layer {...layerStyle.communes} />
                    </Source>
                )}

                {searchCollection !== null &&
                    searchCollection.plots.length > 0 && (
                        <>
                            <Source
                                id="searchCollection"
                                type="geojson"
                                data={{
                                    type: "FeatureCollection",
                                    features: searchCollection.plots.map(
                                        (plot) => {
                                            return {
                                                type: "Feature",
                                                geometry: plot.plot_geometry,
                                                properties: {
                                                    id: plot.id,
                                                    contenance: plot.contenance,
                                                    numero: plot.numero,
                                                    section: plot.section,
                                                    prefixe: plot.prefixe,
                                                },
                                            };
                                        }
                                    ),
                                }}
                                generateId={true}
                            >
                                <Layer
                                    {...layerStyle.searchCollectionParcellesLine}
                                    minzoom={10}
                                />
                                <Layer
                                    {...layerStyle.searchCollectionParcellesFill}
                                    minzoom={10}
                                />
                            </Source>

                            {searchCollection.plots.filter((plot) => {
                                return plot.accepted;
                            }).length > 0 && (
                                <Source
                                    id="keepCollection"
                                    type="geojson"
                                    data={{
                                        type: "FeatureCollection",
                                        features: searchCollection.plots
                                            .filter((plot) => {
                                                return plot.accepted;
                                            })
                                            .map((plot) => {
                                                return {
                                                    type: "Feature",
                                                    geometry:
                                                        plot.plot_geometry,
                                                    properties: {
                                                        id: plot.id,
                                                        contenance:
                                                            plot.contenance,
                                                        numero: plot.numero,
                                                        section: plot.section,
                                                        prefixe: plot.prefixe,
                                                    },
                                                };
                                            }),
                                    }}
                                    generateId={true}
                                >
                                    <Layer
                                        {...layerStyle.keepCollectionParcellesLine}
                                        minzoom={10}
                                    />
                                    <Layer
                                        {...layerStyle.keepCollectionParcellesFill}
                                        minzoom={10}
                                    />
                                </Source>
                            )}
                        </>
                    )}

                {openedCollection !== null &&
                    openedCollection.plots.length > 0 && (
                        <Source
                            id="collection"
                            type="geojson"
                            data={{
                                type: "FeatureCollection",
                                features: openedCollection.plots.map((plot) => {
                                    return {
                                        type: "Feature",
                                        geometry: plot.plot_geometry,
                                        properties: {
                                            id: plot.id,
                                            contenance: plot.contenance,
                                            numero: plot.numero,
                                            section: plot.section,
                                            prefixe: plot.prefixe,
                                        },
                                    };
                                }),
                            }}
                            generateId={true}
                        >
                            <Layer
                                {...layerStyle.currentCollectionParcellesLine}
                                minzoom={10}
                            />
                            <Layer
                                {...layerStyle.currentCollectionParcellesFill}
                                minzoom={10}
                            />
                        </Source>
                    )}

                {showParcelles && (
                    <>
                        <Source
                            id="parcelles"
                            type="geojson"
                            data={parcellesData}
                            tolerance={3}
                            generateId={true}
                        >
                            <Layer {...layerStyle.parcellesFill} />
                            <Layer {...layerStyle.parcellesLine} />
                            <Layer
                                {...layerStyle.parcellesLabel}
                                minzoom={16.5}
                            />
                        </Source>
                        <Source
                            id="bati"
                            type="geojson"
                            data={buildingsData}
                            tolerance={3}
                            generateId={true}
                        >
                            <Layer {...layerStyle.batiLine} />
                        </Source>
                    </>
                )}

                {showSections && (
                    <Source
                        id="sections"
                        type="geojson"
                        data={sectionData}
                        tolerance={3}
                        generateId
                    >
                        <Layer {...layerStyle.sectionsLine} />
                        <Layer
                            {...layerStyle.sectionsFill}
                            minzoom={13}
                            maxzoom={16}
                        />
                        {!showPLU && (
                            <Layer
                                {...layerStyle.sectionsLabel}
                                maxzoom={16.5}
                                minzoom={13}
                            />
                        )}
                    </Source>
                )}

                {showPLU && (
                    <Source
                        id="plu"
                        type="geojson"
                        data={pluData}
                        tolerance={3}
                        generateId
                    >
                        <Layer {...layerStyle.pluLine} />
                        <Layer
                            {...{
                                id: "pluFill",
                                type: "fill",
                                paint: {
                                    "fill-color": [
                                        "case",
                                        ["==", ["get", "typezone"], "A"],
                                        "yellow",
                                        ["==", ["get", "typezone"], "AUc"],
                                        "#900",
                                        ["==", ["get", "typezone"], "AUs"],
                                        "#900",
                                        ["==", ["get", "typezone"], "N"],
                                        "green",
                                        ["==", ["get", "typezone"], "U"],
                                        "red",
                                        "black",
                                    ],
                                    "fill-opacity": [
                                        "case",
                                        [
                                            "boolean",
                                            ["feature-state", "hover"],
                                            false,
                                        ],
                                        0.3,
                                        opacityPLU,
                                    ],
                                },
                            }}
                        />
                        <Layer {...layerStyle.pluLabel} />
                    </Source>
                )}

                {showPresSurf && presSurfData !== null && (
                    <Source
                        id="pres-surf"
                        type="geojson"
                        data={presSurfData}
                        tolerance={3}
                        generateId
                    >
                        <Layer {...layerStyle.presSurfLine} />
                        <Layer
                            {...{
                                id: "presSurfFill",
                                type: "fill",
                                paint: {
                                    "fill-color": "#68C",
                                    "fill-opacity": opacityPresSurf,
                                },
                            }}
                        />
                        <Layer {...layerStyle.presSurfLabel} />
                    </Source>
                )}

                {showPresLin && presLinData !== null && (
                    <Source
                        id="pres-line"
                        type="geojson"
                        data={presLinData}
                        tolerance={3}
                        generateId
                    >
                        <Layer {...layerStyle.presLinLine} />
                        <Layer {...layerStyle.presLinLabel} />
                    </Source>
                )}

                {showPresPct && presPctData !== null && (
                    <Source
                        id="pres-pct"
                        type="geojson"
                        data={presPctData}
                        tolerance={3}
                        generateId
                    >
                        <Layer {...layerStyle.presPctLabel} />
                    </Source>
                )}

                {showMonHist && monHistData !== null && (
                    <Source
                        id="mon-hist"
                        type="geojson"
                        data={{
                            type: "FeatureCollection",
                            features: monHistData.monHist.map((monHist) => {
                                return {
                                    type: "Feature",
                                    geometry: monHist.geometry,
                                    properties: monHist.properties,
                                };
                            }),
                        }}
                        tolerance={3}
                        generateId
                    >
                        <Layer {...layerStyle.monHistSymbol} />
                    </Source>
                )}

                {showPEB && (
                    <>
                        <Source
                            id="peb"
                            type="geojson"
                            data={PEB}
                            tolerance={10}
                            generateId
                        >
                            <Layer {...layerStyle.pebLine} />
                            <Layer
                                {...{
                                    id: "pebFill",
                                    type: "fill",
                                    paint: {
                                        "fill-color": [
                                            "case",
                                            ["==", ["get", "ZONE"], "A"],
                                            "red",
                                            ["==", ["get", "ZONE"], "B"],
                                            "orange",
                                            ["==", ["get", "ZONE"], "C"],
                                            "green",
                                            ["==", ["get", "ZONE"], "D"],
                                            "blue",
                                            "black",
                                        ],
                                        "fill-opacity": opacityPEB,
                                    },
                                }}
                            />
                        </Source>
                    </>
                )}

                {showPadels && (
                    <Source
                        id="padels"
                        type="geojson"
                        data={padelsData}
                        generateId
                    >
                        <Layer {...layerStyle.padelsSymbol} />
                    </Source>
                )}

                {showPPR && pprData !== null && (
                    <Source
                        id="PPR"
                        type="geojson"
                        data={{
                            type: "FeatureCollection",
                            features: pprData.PPR.map((ppr) => {
                                return {
                                    type: "Feature",
                                    geometry:
                                        ppr.geom_perimetre.features[0].geometry,
                                    properties: {
                                        date_approbation: ppr.date_approbation,
                                        date_fin_validite:
                                            ppr.date_fin_validite,
                                        etat: ppr.etat,
                                        id_gaspar: ppr.id_gaspar,
                                        libelle_commune: ppr.libelle_commune,
                                        nom_ppr: ppr.nom_ppr,
                                        risque: ppr.risque,
                                        libelle_risque:
                                            ppr.risque.libelle_risque,
                                    },
                                };
                            }),
                        }}
                        tolerance={3}
                        generateId
                    >
                        <Layer {...layerStyle.PPRLine} />
                        <Layer
                            {...{
                                id: "PPRFill",
                                type: "fill",
                                paint: {
                                    "fill-color": "#F52",
                                    "fill-opacity": opacityPPR,
                                },
                            }}
                        />
                        <Layer {...layerStyle.PPRLabel} />
                    </Source>
                )}

                {isCatchmentAreaActive && (
                    <Source
                        id="catchmentArea"
                        type="geojson"
                        data={{
                            type: "FeatureCollection",
                            features: [],
                        }}
                        tolerance={3}
                        generateId
                    >
                        <Layer
                            {...{
                                id: "catchmentAreaFill",
                                type: "fill",
                                source: "catchmentArea",
                                paint: {
                                    "fill-color": "#5a3fc0",
                                    "fill-opacity": 0.5,
                                },
                            }}
                        />
                    </Source>
                )}

                {isRulerActive && rulerLine.length > 2 && (
                    <Source
                        id="ruler"
                        type="geojson"
                        data={{
                            type: "FeatureCollection",
                            features: [
                                {
                                    type: "Feature",
                                    geometry: {
                                        type: "Polygon",
                                        coordinates: [
                                            rulerLine.map((locations) => [
                                                locations.lng,
                                                locations.lat,
                                            ]),
                                            [
                                                rulerLine[0].lng,
                                                rulerLine[0].lat,
                                            ],
                                        ],
                                    },
                                },
                            ],
                        }}
                        tolerance={3}
                        generateId
                    >
                        <Layer {...layerStyle.rulerLine} />
                        <Layer {...layerStyle.rulerLineFill} />
                        <Layer {...layerStyle.rulerLineDashray} />
                    </Source>
                )}

                {isRulerActive && rulerLine.length === 2 && (
                    <Source
                        id="ruler"
                        type="geojson"
                        data={{
                            type: "FeatureCollection",
                            features: [
                                {
                                    type: "Feature",
                                    geometry: {
                                        type: "LineString",
                                        coordinates: rulerLine.map(
                                            (locations) => [
                                                locations.lng,
                                                locations.lat,
                                            ]
                                        ),
                                    },
                                },
                            ],
                        }}
                        tolerance={3}
                        generateId
                    >
                        <Layer {...layerStyle.rulerLine} />
                        <Layer {...layerStyle.rulerLineDashray} />
                    </Source>
                )}

                <LayerControl
                    onLayerChange={handleLayerChange}
                    layers={[
                        {
                            id: "PLU",
                            name: "Zones du PLU",
                            visible: showPLU,
                            available:
                                pluData !== null &&
                                pluData.insee === lastCommune &&
                                pluData.features.length > 0,
                            empty: pluData
                                ? pluData.insee === lastCommune
                                    ? pluData.features.length === 0
                                    : false
                                : false,
                            loading: fetchingPLU === "waiting " + lastCommune,
                            opacity: opacityPLU,
                        },
                        {
                            id: "pres-surf",
                            name: "Prescriptions surfaciques",
                            visible: showPresSurf,
                            available:
                                presSurfData !== null &&
                                presSurfData.insee === lastCommune,
                            empty: presSurfData
                                ? presSurfData.insee === lastCommune
                                    ? presSurfData.features.length === 0
                                    : false
                                : false,
                            loading:
                                fetchingPresSurf === "waiting " + lastCommune,
                            opacity: opacityPresSurf,
                        },
                        {
                            id: "pres-lin",
                            name: "Prescriptions linéaires",
                            visible: showPresLin,
                            available:
                                presLinData !== null &&
                                presLinData.insee === lastCommune,
                            empty: presLinData
                                ? presLinData.insee === lastCommune
                                    ? presLinData.features.length === 0
                                    : false
                                : false,
                            loading:
                                fetchingPresLin === "waiting " + lastCommune,
                        },
                        {
                            id: "pres-pct",
                            name: "Prescriptions ponctuelles",
                            visible: showPresPct,
                            available:
                                presPctData !== null &&
                                presPctData.insee === lastCommune,
                            empty: presPctData
                                ? presPctData.insee === lastCommune
                                    ? presPctData.features.length === 0
                                    : false
                                : false,
                            loading:
                                fetchingPresPct === "waiting " + lastCommune,
                        },
                        {
                            id: "PPR",
                            name: "Plans de Prévention des Risques",
                            visible: showPPR,
                            available:
                                pprData !== null &&
                                pprData.insee === lastCommune,
                            empty: pprData
                                ? pprData.insee === lastCommune
                                    ? pprData.PPR.length === 0
                                    : false
                                : false,
                            loading: fetchingPPR === "waiting " + lastCommune,
                            opacity: opacityPPR,
                        },
                        {
                            id: "mon-hist",
                            name: "Monuments historiques",
                            visible: showMonHist,
                            available:
                                monHistData !== null &&
                                monHistData.insee === lastCommune,
                            empty: monHistData
                                ? monHistData.insee === lastCommune
                                    ? monHistData.monHist.length === 0
                                    : false
                                : false,
                            loading:
                                fetchingMonHist === "waiting " + lastCommune,
                        },
                        {
                            id: "padels",
                            name: "Clubs de padel",
                            visible: showPadels,
                            available: true,
                            empty: false,
                            loading: false,
                        },
                        {
                            id: "PEB",
                            name: "Plan d'Exposition au Bruit (PEB)",
                            visible: showPEB,
                            available: true,
                            empty: false,
                            loading: false,
                            opacity: opacityPEB,
                        },
                    ]}
                />
                <HoverInfo data={hoverInfo} />
                {contextMenuState.visible && (
                    <ContextMenu
                        parcelle={contextMenuState.parcelle.properties}
                        pos={contextMenuState.pos}
                        onClose={handleContextClose}
                        lngLat={contextMenuState.lngLat}
                        address={contextMenuState.address}
                    />
                )}
                <Measure
                    onActiveChange={setIsRulerActive}
                    onChange={setRulerData}
                />
                <CatchmentArea
                    onActiveChange={setIsCatchmentAreaActive}
                    communesData={communesData}
                />
                <ScaleControl position="bottom-right" maxWidth={200} />
                <NavigationControl position="bottom-right" />
            </Map>
        );
    } else {
        return (
            <Map
                id="map"
                initialViewState={initialViewState}
                mapStyle="mapbox://styles/mapbox/satellite-v9"
                style={{
                    position: "absolute",
                    width: "100vw",
                    height: "100vh",
                    top: 0,
                    zIndex: -1,
                }}
                mapboxAccessToken="pk.eyJ1Ijoicm11bnRkcyIsImEiOiJjbGRucGtxZTgwazBpM3BxZzdpOXBsdTlkIn0.Ixv9Rh6eRAgrN0hkYWi8KA"
            ></Map>
        );
    }
}

export default Mapbox;
