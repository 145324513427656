import React, { useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";

import { useGlobalState } from "../pages/app-container";

import catchmentAreaIcon from "../assets/images/catchment-area.png";
import "../style/catchment-area.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "./loader";

const requestIsochrone = async (lng, lat, travelTime) => {
    const response = await fetch(
        `https://api.mapbox.com/isochrone/v1/mapbox/driving/${lng},${lat}?contours_minutes=${travelTime}&polygons=true&access_token=pk.eyJ1Ijoicm11bnRkcyIsImEiOiJjbGRucGtxZTgwazBpM3BxZzdpOXBsdTlkIn0.Ixv9Rh6eRAgrN0hkYWi8KA`
    );
    const data = await response.json();
    return data;
};

const requestPopulationForCity = async (commune) => {
    const population = await fetch(
        `https://geo.api.gouv.fr/communes?code=${commune.properties.id}`
    );
    const data = await population.json();
    return data?.[0].population;
};

const getCatchmentAreaData = async (communesData, catchmentAreaData) => {
    let catchementAreaResult = {
        totalPopulation: 0,
        cities: [],
    };

    for (const commune of communesData.features) {
        // Calcule l'intersection entre la commune et la zone d'isochrone
        const intersection = turf.intersect(
            commune,
            catchmentAreaData.features?.[0]
        );

        if (intersection) {
            const populationCommune = await requestPopulationForCity(
                commune
            ).then((data) => {
                return data;
            });

            // Calcule les surfaces (en km² par exemple)
            const surfaceCommune = turf.area(commune);
            const surfaceIntersection = turf.area(intersection);

            if (populationCommune >= 100000) {
                // Calcule la population proportionnelle dans la zone
                const populationProportionnelle =
                    populationCommune * (surfaceIntersection / surfaceCommune);

                catchementAreaResult.cities.push({
                    commune,
                    populationCommune,
                    populationCalculee: Math.round(populationProportionnelle),
                    calculateProportianalPopulation: true,
                    surfaceCommune,
                    surfaceIntersection,
                });

                // Ajoute à la population totale
                catchementAreaResult.totalPopulation += Math.round(
                    populationProportionnelle
                );
            } else {
                catchementAreaResult.cities.push({
                    commune,
                    populationCommune,
                    populationCalculee: populationCommune,
                    calculateProportianalPopulation: false,
                    surfaceCommune,
                    surfaceIntersection,
                });

                // Ajoute à la population totale
                catchementAreaResult.totalPopulation += populationCommune;
            }
        }
    }

    return catchementAreaResult;
};

const CatchmentAreaPopup = ({
    travelTime,
    handleChange,
    onClose,
    calculatePopulationInCatchmentArea,
    citiesInCatchementArea,
    setIsCatchmentAreaResultPopupActive,
}) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        calculatePopulationInCatchmentArea();
    };

    return (
        <div className="catchment-area-popup-container">
            <FontAwesomeIcon
                icon="fa-solid fa-close"
                id="catchment-area-popup-close"
                onClick={onClose}
            />
            <div className="catchment-area-popup-header">
                <img
                    src={catchmentAreaIcon}
                    alt="Icone de zone de chalandise"
                    height="24px"
                />
                <h3>Zone de chalandise</h3>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="catchment-area-popup-content">
                    <span className="catchment-area-popup-label">
                        Cliquez sur la carte pour définir le point de départ
                    </span>
                </div>
                <div className="catchment-area-popup-content">
                    <span className="catchment-area-popup-label">
                        Temps de trajet :
                    </span>
                    <span className="catchment-area-popup-value">
                        <input
                            name="travelTime"
                            type="number"
                            min={0}
                            max={30}
                            value={travelTime}
                            onChange={handleChange}
                        />{" "}
                        minutes
                    </span>
                </div>
                <div className="catchment-area-popup-footer">
                    <button type="submit">Valider</button>
                    {citiesInCatchementArea.length > 0 && (
                        <button
                            type="button"
                            onClick={() =>
                                setIsCatchmentAreaResultPopupActive(true)
                            }
                        >
                            Voir résultats
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

const CatchmentAreaResultPopup = ({
    totalPopulationInCatchmentArea,
    citiesInCatchementArea,
    onClose,
    isCatchmentAreaResultPopupLoading,
    setCitiesInCatchementArea,
    recalculatePopulationInCatchmentArea,
    recalculatePopulationAvailable,
    setRecalculatePopulationAvailable,
    canSetInitialCities,
    setCanSetInitialCities,
}) => {
    const [citiesInCatchementAreaOrdered, setCitiesInCatchementAreaOrdered] =
        useState([]);

    const [
        initialCitiesInCatchementAreaOrdered,
        setInitialCitiesInCatchementAreaOrdered,
    ] = useState([]);

    useEffect(() => {
        const citiesOrdered = citiesInCatchementArea.slice().sort((a, b) => {
            return b.populationCalculee - a.populationCalculee;
        });
        setCitiesInCatchementAreaOrdered(citiesOrdered);
        if (canSetInitialCities && citiesInCatchementArea.length > 0) {
            setInitialCitiesInCatchementAreaOrdered(citiesOrdered);
            setCanSetInitialCities(false);
        }
    }, [citiesInCatchementArea]);

    const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.every(
            (city, index) =>
                city.calculateProportianalPopulation ===
                arr2[index].calculateProportianalPopulation
        );
    };

    useEffect(() => {
        if (
            areArraysEqual(
                citiesInCatchementAreaOrdered,
                initialCitiesInCatchementAreaOrdered
            )
        ) {
            setRecalculatePopulationAvailable(false);
        } else {
            setRecalculatePopulationAvailable(true);
        }
    }, [citiesInCatchementAreaOrdered]);

    const changePopulationCalculation = (cityId) => {
        setCitiesInCatchementArea((prevCities) => {
            return prevCities.map((city) => {
                if (city.commune.properties.id === cityId) {
                    return {
                        ...city,
                        calculateProportianalPopulation:
                            !city.calculateProportianalPopulation,
                    };
                }
                return city;
            });
        });
    };

    return (
        <div className="catchment-area-result-popup-container">
            <FontAwesomeIcon
                icon="fa-solid fa-close"
                id="catchment-area-result-popup-close"
                onClick={onClose}
            />
            {!isCatchmentAreaResultPopupLoading ? (
                <div className="catchment-area-result-popup-wrapper">
                    <div className="catchment-area-result-popup-header">
                        <h3>Résultats sur cette zone de chalandise</h3>
                    </div>
                    <div className="catchment-area-result-popup-content">
                        <span className="catchment-area-result-popup-label">
                            Population totale dans la zone:
                        </span>
                        <span className="catchment-area-result-popup-value population-totale">
                            {" " +
                                totalPopulationInCatchmentArea.toLocaleString(
                                    "fr-FR"
                                ) +
                                " habitants"}
                        </span>
                    </div>
                    <div className="catchment-area-result-popup-content cities-detail">
                        <span className="catchment-area-result-popup-label">
                            Détails des villes présentes dans la zone:
                        </span>
                    </div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Population totale</th>
                                    <th>Population calculée</th>
                                    <th>Intersection</th>
                                    <th>Calcul proportionnel</th>
                                </tr>
                            </thead>
                            <tbody>
                                {citiesInCatchementAreaOrdered.map((city) => {
                                    const pourcentageIntersection = Math.round(
                                        (city.surfaceIntersection /
                                            city.surfaceCommune) *
                                            100
                                    );

                                    return (
                                        <tr key={city.commune.properties.id}>
                                            <th>
                                                {city.commune.properties.nom}
                                            </th>
                                            <td>
                                                {city.populationCommune.toLocaleString(
                                                    "fr-FR"
                                                )}
                                            </td>
                                            {city.calculateProportianalPopulation ? (
                                                <td>
                                                    {city.populationCalculee.toLocaleString(
                                                        "fr-FR"
                                                    )}
                                                </td>
                                            ) : (
                                                <td></td>
                                            )}
                                            <td
                                                className={
                                                    pourcentageIntersection <=
                                                    25
                                                        ? "low-intersection-percentage"
                                                        : ""
                                                }
                                            >
                                                {pourcentageIntersection + " %"}
                                            </td>
                                            <td
                                                className={
                                                    "proportional-toggle" +
                                                    (city.calculateProportianalPopulation
                                                        ? " active"
                                                        : "")
                                                }
                                            >
                                                <div
                                                    className="toggle-outer"
                                                    onClick={() =>
                                                        changePopulationCalculation(
                                                            city.commune
                                                                .properties.id
                                                        )
                                                    }
                                                >
                                                    <div className="toggle-inner" />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="recalculate-container">
                        <button
                            className={
                                "recalculate" +
                                (recalculatePopulationAvailable
                                    ? " available"
                                    : "")
                            }
                            onClick={recalculatePopulationInCatchmentArea}
                        >
                            Recalculer les populations
                        </button>
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </div>
    );
};

function CatchmentArea({ onActiveChange, communesData }) {
    const [hideCollectionTab, setHideCollectionTab] =
        useGlobalState("hideCollectionTab");

    const { current: map } = useMap();

    const [isCatchmentAreaPopupActive, setIsCatchmentAreaPopupActive] =
        useState(false);
    const [
        isCatchmentAreaResultPopupActive,
        setIsCatchmentAreaResultPopupActive,
    ] = useState(false);

    const [marker, setMarker] = useState(null);
    const [travelTime, setTravelTime] = useState(0);

    const [totalPopulationInCatchmentArea, setTotalPopulationInCatchmentArea] =
        useState(0);
    const [citiesInCatchementArea, setCitiesInCatchementArea] = useState([]);
    const [
        isCatchmentAreaResultPopupLoading,
        setIsCatchmentAreaResultPopupLoading,
    ] = useState(false);
    const [recalculatePopulationAvailable, setRecalculatePopulationAvailable] =
        useState(false);
    const [canSetInitialCities, setCanSetInitialCities] = useState(true);

    useEffect(() => {
        map.on("click", handleMapClick);

        return () => {
            map.off("click", handleMapClick);
        };
    });

    const handleChange = (e) => {
        switch (e.target.name) {
            case "travelTime":
                if (e.target.value < 0) {
                    setTravelTime(0);
                } else if (e.target.value > 30) {
                    setTravelTime(30);
                } else {
                    setTravelTime(e.target.value);
                }
                break;
            default:
                break;
        }
    };

    const handleMapClick = (e) => {
        if (isCatchmentAreaPopupActive) {
            if (marker) {
                removeMarker();
            }
            createMarker(e.lngLat);
        }
    };

    const resetCatchmentArea = () => {
        onActiveChange(false);
        removeMarker();
        setIsCatchmentAreaPopupActive(false);
        setIsCatchmentAreaResultPopupActive(false);
        setHideCollectionTab(false);
        setTravelTime(0);
        setTotalPopulationInCatchmentArea(0);
        setCitiesInCatchementArea([]);
        setIsCatchmentAreaResultPopupLoading(false);
        setRecalculatePopulationAvailable(false);
        setCanSetInitialCities(true);
    };

    const handleClick = (e) => {
        if (e.target.id === "catchment-area-icon") {
            if (!isCatchmentAreaPopupActive) {
                setHideCollectionTab(true);
                setIsCatchmentAreaPopupActive(true);
                onActiveChange(true);
            } else {
                resetCatchmentArea();
            }
        }
    };

    const handleCatchmentAreaPopupClose = () => {
        resetCatchmentArea();
    };

    const handleCatchmentAreaResultPopupClose = () => {
        setIsCatchmentAreaResultPopupActive(false);
    };

    const createMarker = (location) => {
        let markerElt = document.createElement("div");
        markerElt.className = "catchment-area-marker";
        markerElt.id = "catchment-area-marker";
        markerElt.innerText = "i";

        const marker = new mapboxgl.Marker({
            element: markerElt,
            draggable: false,
        });
        marker.setLngLat([location.lng, location.lat]);
        marker.addTo(map.getMap());
        setMarker(marker);
    };

    const removeMarker = () => {
        marker?.remove();
        setMarker(null);
    };

    const calculatePopulationInCatchmentArea = async () => {
        setIsCatchmentAreaResultPopupLoading(true);
        setIsCatchmentAreaResultPopupActive(true);

        if (marker) {
            requestIsochrone(
                marker._lngLat.lng,
                marker._lngLat.lat,
                travelTime
            ).then(async (data) => {
                map.getSource("catchmentArea").setData(data);

                const catchmentAreaResult = await getCatchmentAreaData(
                    communesData,
                    data
                );

                setTotalPopulationInCatchmentArea(
                    catchmentAreaResult.totalPopulation
                );

                setCitiesInCatchementArea(catchmentAreaResult.cities);
                setIsCatchmentAreaResultPopupLoading(false);
            });
        } else {
            console.log("No marker");
            setIsCatchmentAreaResultPopupLoading(false);
        }
    };

    const recalculatePopulationInCatchmentArea = () => {
        setIsCatchmentAreaResultPopupLoading(true);
        setIsCatchmentAreaResultPopupActive(true);
        setRecalculatePopulationAvailable(false);
        setCanSetInitialCities(true);

        let catchementAreaResult = {
            totalPopulation: 0,
            cities: [],
        };

        for (const city of citiesInCatchementArea) {
            // Calcule les surfaces (en km² par exemple)
            const surfaceCommune = city.surfaceCommune;
            const surfaceIntersection = city.surfaceIntersection;

            if (city.calculateProportianalPopulation) {
                // Calcule la population proportionnelle dans la zone
                const populationProportionnelle =
                    city.populationCommune *
                    (surfaceIntersection / surfaceCommune);

                catchementAreaResult.cities.push({
                    commune: city.commune,
                    populationCommune: city.populationCommune,
                    populationCalculee: Math.round(populationProportionnelle),
                    calculateProportianalPopulation: true,
                    surfaceCommune,
                    surfaceIntersection,
                });

                // Ajoute à la population totale
                catchementAreaResult.totalPopulation += Math.round(
                    populationProportionnelle
                );
            } else {
                catchementAreaResult.cities.push({
                    commune: city.commune,
                    populationCommune: city.populationCommune,
                    populationCalculee: city.populationCommune,
                    calculateProportianalPopulation: false,
                    surfaceCommune,
                    surfaceIntersection,
                });

                // Ajoute à la population totale
                catchementAreaResult.totalPopulation += city.populationCommune;
            }
        }

        setTotalPopulationInCatchmentArea(catchementAreaResult.totalPopulation);
        setCitiesInCatchementArea(catchementAreaResult.cities);
        setIsCatchmentAreaResultPopupLoading(false);
    };

    return (
        <div
            id="catchment-area-button"
            className={
                "catchment-area-button " +
                (isCatchmentAreaPopupActive && "active")
            }
            onClick={handleClick}
        >
            <img
                id="catchment-area-icon"
                src={catchmentAreaIcon}
                alt=""
                height="32px"
            />
            {isCatchmentAreaPopupActive && (
                <CatchmentAreaPopup
                    travelTime={travelTime}
                    handleChange={handleChange}
                    onClose={handleCatchmentAreaPopupClose}
                    calculatePopulationInCatchmentArea={
                        calculatePopulationInCatchmentArea
                    }
                    citiesInCatchementArea={citiesInCatchementArea}
                    setIsCatchmentAreaResultPopupActive={
                        setIsCatchmentAreaResultPopupActive
                    }
                />
            )}
            {isCatchmentAreaResultPopupActive && (
                <CatchmentAreaResultPopup
                    totalPopulationInCatchmentArea={
                        totalPopulationInCatchmentArea
                    }
                    citiesInCatchementArea={citiesInCatchementArea}
                    onClose={handleCatchmentAreaResultPopupClose}
                    calculatePopulationInCatchmentArea={
                        calculatePopulationInCatchmentArea
                    }
                    isCatchmentAreaResultPopupLoading={
                        isCatchmentAreaResultPopupLoading
                    }
                    setCitiesInCatchementArea={setCitiesInCatchementArea}
                    recalculatePopulationInCatchmentArea={
                        recalculatePopulationInCatchmentArea
                    }
                    recalculatePopulationAvailable={
                        recalculatePopulationAvailable
                    }
                    setRecalculatePopulationAvailable={
                        setRecalculatePopulationAvailable
                    }
                    canSetInitialCities={canSetInitialCities}
                    setCanSetInitialCities={setCanSetInitialCities}
                />
            )}
        </div>
    );
}

export default CatchmentArea;
