import pako from 'pako';
import * as turf from '@turf/turf';

import departements from '../assets/geojson/departements.json';

const CadastreServices = {

    getPLUiPartitionByInsee: (insee) => {

        const lauRevSorezois = [31451, 31060, 81016, 81027, 81032, 81049, 81083, 81100, 31243, 31180, 31570, 81142, 11054, 81055, 31329, 31371, 81179, 31393, 31400, 81200, 81210, 81214, 31463, 31478, 81237, 31491, 81288, 31569]
        const sorAgout = [81001, 81015, 81050, 81076, 81084, 81129, 81160, 81189, 81219, 81242, 81270, 81281, 81312, 81006, 81030, 81054, 81081, 81127, 81143, 81162, 81205, 81235, 81251, 81273, 81289, 81325]
        const albigeois = [81004, 81018, 81052, 81059, 81063, 81074, 81079, 81097, 81144, 81156, 81218, 81150, 81257, 81274, 81284, 81297]
        //const grandSudTarnEtGaronne = [ /* Aucamville OK 82005 */, /* BEAUPUY Manquant */82014, 82017, 82020, 82023, 82027, 82028, 82043, 82048, 82057, 82062, 82075, 82079, 82105, 82114, 82123, 82125, 82135, 82136, 82142, 82173, 82178, 82188, 82190, 82194]
        const rodez = [12090, 12146, 12133, 12176, 12174, 12202, 12241, 12264]
        const aixProvenceA = [13002, 13102, 13104, 13106, 13119, 13021, 13022, 13023, 13026, 13028, 13042, 13043, 13054, 13201, 13202, 13203, 13204, 13205, 13206, 13207, 13208, 13209, 13210, 13211, 13212, 13213, 13214, 13215, 13216, 13075, 13085]
        const aixProvenceD = [13005, 13007, 13016, 13031, 13070, 13073, 13086]
        const bordeau = [33003, 33013, 33032, 33039, 33056, 33063, 33069, 33075, 33096, 33119, 33162, 33167, 33192, 33200, 33249, 33273, 33281, 33312, 33318, 33376, 33449, 33519, 33522, 33550]
        const sudGironde = [33227]
        const grandDax = [40088, 40279]
        const montMarsans = [40192, 40281]
        const adourCoteSud = [40065, 40133, 40284, 40296, 40304, 40310, 40317]
        const nazairienne = [44052, 44103, 44132, 44151, 44184, 44210]
        const nantesMetropole = [44018, 44009, 44020, 44026, 44035, 44047, 44109, 44114, 44143, 44162, 44190, 44172, 44194, 44198, 44204, 44215]
        const erdreGesvres = [44110, 44209]
        const doueAnjou = [49125]
        const saumurValLoire = [49328]
        const angersLoire = [49007, 49015, 49020, 49035, 49307, 49214, 49353, 49323, 49267]
        const tarbesPyrenees = [65235]
        const millauGrandCausse = [12145, 12225, 12002, 12200, 12086, 12160, 12180, 12178, 12204, 12211, 12293]

        if (sorAgout.includes(parseInt(insee))) return "248100158";
        else if (albigeois.includes(parseInt(insee))) return "248100737";
        //else if(grandSudTarnEtGaronne.includes(parseInt(insee))) return "248200737";
        else if (rodez.includes(parseInt(insee))) return "241200187";
        else if (lauRevSorezois.includes(parseInt(insee))) return "243100567";
        else if (aixProvenceA.includes(parseInt(insee))) return "200054807_A";
        else if (aixProvenceD.includes(parseInt(insee))) return "200054807_D";
        else if (bordeau.includes(parseInt(insee))) return "243300316";
        else if (sudGironde.includes(parseInt(insee))) return "200043974";
        else if (grandDax.includes(parseInt(insee))) return "244000675";
        else if (montMarsans.includes(parseInt(insee))) return "244000808";
        else if (adourCoteSud.includes(parseInt(insee))) return "244000865";
        else if (nazairienne.includes(parseInt(insee))) return "244400644";
        else if (nantesMetropole.includes(parseInt(insee))) return "244400404";
        else if (erdreGesvres.includes(parseInt(insee))) return "244400503";
        else if (doueAnjou.includes(parseInt(insee))) return "244900973";
        else if (saumurValLoire.includes(parseInt(insee))) return "200071876_A";
        else if (angersLoire.includes(parseInt(insee))) return "244900015";
        else if (tarbesPyrenees.includes(parseInt(insee))) return "200069300_A";
        else if (millauGrandCausse.includes(parseInt(insee))) return "241200567";
        else return null;
    },

    getPlotsByInsee: async (insee) => {

        let codeInsee = insee + "";
        let dpt = codeInsee.substring(0, 2);
        if (dpt === "97") dpt = codeInsee.substring(0, 3);

        const plots = await fetch('https://cadastre.data.gouv.fr/data/etalab-cadastre/latest/geojson/communes/' + dpt + '/' + codeInsee + '/cadastre-' + codeInsee + '-parcelles.json.gz')
            .then(response => response.arrayBuffer())
            .then(buffer => new Uint8Array(buffer))
            .then(gzipped => pako.ungzip(gzipped, { to: 'string' }))
            .then(unzipped => JSON.parse(unzipped));

        return plots;
    },

    getBatiByInsee: async (insee) => {
        let dpt = insee.substring(0, 2);
        if (dpt === "97") dpt = insee.substring(0, 3);

        const response = await fetch('https://cadastre.data.gouv.fr/data/etalab-cadastre/latest/geojson/communes/' + dpt + '/' + insee + '/cadastre-' + insee + '-batiments.json.gz')
            .then(response => response.arrayBuffer())
            .then(buffer => new Uint8Array(buffer))
            .then(gzipped => pako.ungzip(gzipped, { to: 'string' }))
            .then(unzipped => JSON.parse(unzipped));

        return response;
    },

    getSectionsByInsee: async (insee) => {
        const response = await fetch('https://apicarto.ign.fr/api/cadastre/feuille?code_insee=' + insee);
        const data = await response.json();
        return data;
    },

    getDataByCoord: async (lat, lon) => {
        let response = undefined;
        try {
            response = await fetch('https://api-adresse.data.gouv.fr/reverse/?lon=' + lon + '&lat=' + lat);
        } catch (error) {
            return -1;
        }
        const data = await response.json();

        if (data.features.length === 0) {
            console.log("No data found for this location")
            return "no data";
        }
        if (data.features[0].properties.citycode !== undefined) {
            return data.features[0].properties
        } else {
            for (let i = 0; i < data.features.length; i++) {
                if (data.features[i].properties.citycode !== undefined) {
                    return data.features[i].properties
                }
            }
            return "no data";
        }
    },

    getCommunesByDepartement: async (dpt) => {
        if (dpt === undefined) return null;

        const response = await fetch('https://cadastre.data.gouv.fr/data/etalab-cadastre/latest/geojson/departements/' + dpt + '/cadastre-' + dpt + '-communes.json.gz')
            .then(response => response.arrayBuffer())
            .then(buffer => new Uint8Array(buffer))
            .then(gzipped => pako.ungzip(gzipped, { to: 'string' }))
            .then(unzipped => JSON.parse(unzipped));

        return response;
    },

    getCommuneByInsee: async (insee) => {
        const response = await fetch('https://apicarto.ign.fr/api/cadastre/commune?code_insee=' + insee);
        const data = await response.json();
        return data;
    },

    getPLUByInsee: async (insee) => {
        let partition = CadastreServices.getPLUiPartitionByInsee(insee) !== null ? CadastreServices.getPLUiPartitionByInsee(insee) : insee;
        if (insee === "81004") partition = "248100737"


        const response = await fetch('https://apicarto.ign.fr/api/gpu/zone-urba?partition=DU_' + partition);
        const data = await response.json();
        return { ...data, insee: insee };
    },

    getZonesPLUByInsee: async (insee) => {
        let partition = CadastreServices.getPLUiPartitionByInsee(insee) !== null ? CadastreServices.getPLUiPartitionByInsee(insee) : insee;
        const response = await fetch('https://apicarto.ign.fr/api/gpu/zone-urba?partition=DU_' + partition);
        const data = await response.json();

        if (data.features.length === 0) return null;

        let zones = [];

        for (let i = 0; i < data.features.length; i++) {
            let zone = {
                zone: data.features[i].properties.libelle,
                label: data.features[i].properties.libelong,
                typezone: data.features[i].properties.typezone
            }
            if (zones.filter((z) => z.zone === zone.zone).length === 0) zones.push(zone);
        }
        return { zones: zones, plu: data };
    },

    getPrescriptionSurfByInsee: async (insee) => {
        const response = await fetch('https://apicarto.ign.fr/api/gpu/prescription-surf?partition=DU_' + insee);
        const data = await response.json();

        return { ...data, insee: insee };
    },

    getPrescriptionLinByInsee: async (insee) => {
        const response = await fetch('https://apicarto.ign.fr/api/gpu/prescription-lin?partition=DU_' + insee);
        const data = await response.json();

        return { ...data, insee: insee };
    },

    getPrescriptionPctByInsee: async (insee) => {
        const response = await fetch('https://apicarto.ign.fr/api/gpu/prescription-pct?partition=DU_' + insee);
        const data = await response.json();

        return { ...data, insee: insee };
    },

    getPlotDetails: async (numero, section, prefixe, dpt, insee) => {

        if (!numero || !section) {
            console.log("Missing data to get plot details");
            return {
                "type": "FeatureCollection",
                "features": [],
            };
        }

        // make sure numero is a 4 char string, if not, add 0s at the beginning

        if (section.length === 1) section = "0" + section;

        if (numero.length > 0 && section.length === 2) {

            while (numero.length < 4) numero = "0" + numero;

            if (prefixe && prefixe.length > 0)
                while (prefixe.length < 3) prefixe = "0" + prefixe;

            const response = await fetch("https://apicarto.ign.fr/api/cadastre/parcelle?" +
                "section=" + section +
                "&numero=" + numero +
                (dpt && dpt.length > 0 ? "&code_dep=" + dpt : "") +
                (prefixe ? "&com_abs=" + prefixe : "") +
                (insee ? "&code_insee=" + insee : ""));
            if (response.status === 200) {
                const data = await response.json();
                return data;
            }
        }
        return "erreur"
    },

    getCitiesByName: async (name) => {
        const response = await fetch('https://geo.api.gouv.fr/communes?nom=' + name + '&fields=nom,code,codesPostaux,centre&format=json&geometry=centre');
        const data = await response.json();
        return data;
    },

    getCityByInsee: async (insee) => {
        const response = await fetch('https://geo.api.gouv.fr/communes/?code=' + insee + '&fields=nom,code,codesPostaux,centre&format=json&geometry=centre');
        const data = await response.json();
        return data;
    },

    getDepartementsByCoords: (lon, lat) => {
        for (const dpt of departements.features) {
            if (turf.booleanPointInPolygon([lon, lat], dpt)) return dpt.properties.code;
        }
    },

    getMonumentHistoriquesByInsee: async (insee) => {
        const response = await fetch("https://data.culture.gouv.fr/api/records/1.0/search/?dataset=liste-des-immeubles-proteges-au-titre-des-monuments-historiques&q=" + insee + "&facet=region&facet=departement")
        const data = await response.json();
        return { monHist: data.records.map((feature) => { return { type: "Feature", geometry: feature.geometry, properties: feature.fields } }), insee: data.parameters.q };
    },

    getProfileAltimetrique: async (pt1, pt2) => {
        const response = await fetch('https://data.geopf.fr/altimetrie/1.0/calcul/alti/rest/elevationLine.json?lon=' + pt1.lng + '|' + pt2.lng + '&lat=' + pt1.lat + '|' + pt2.lat + '&resource=ign_rge_alti_wld&indent=true&sampling=10')
        const data = await response.json();
        return data;
    },

    search: async (insee, sections, zones, superficie, ratioBatiTerrain) => {
        let plots = await CadastreServices.getPlotsByInsee(insee);
        console.log("parcelles de la ville : ")
        console.log(plots)

        if (plots.features.length === 0) return null;

        if (sections) {
            plots.features = plots.features.filter((plot) => {
                return sections.includes(plot.properties.section);
            });
            console.log("Parcelles incluses dans les sections : " + sections.join(", ") + " : ")
            console.log(plots)
        }

        if (zones) {
            let plu = await CadastreServices.getPLUByInsee(insee);
            if (plu.features.length > 0) {
                plots.features = plots.features.filter((plot) => {
                    let isInZone = false;
                    for (let i = 0; i < plu.features.length; i++) {
                        if (turf.booleanPointInPolygon(plot.geometry.coordinates, plu.features[i].geometry)) {
                            if (zones.includes(plu.features[i].properties.libelong)) isInZone = true;
                        }
                    }
                    return isInZone;
                });
            }

            console.log("Parcelles incluses dans les zones : " + zones.join(", ") + " : ")
            console.log(plots)
        }

        if (superficie) {
            plots.features = plots.features.filter((plot) => {
                return plot.properties.contenance > superficie;
            });

            console.log("Parcelles avec une superficie supérieure à " + superficie + " : ")
            console.log(plots)
        }

        if (ratioBatiTerrain) {
            plots.features = plots.features.filter((plot) => {
                return plot.properties.ratioBatiTerrain > ratioBatiTerrain;
            });

            console.log("Parcelles avec un ratio bâti/terrain supérieur à " + ratioBatiTerrain + " : ")
            console.log(plots)
        }

        return plots;
    },

    getPPRByInsee: async (insee) => {
        let page = 1;
        let nbResults;
        let PPR = [];

        do {
            const response = await fetch('https://www.georisques.gouv.fr/api/v1/ppr?code_insee=' + insee + '&page=' + page + '&page_size=10&rayon=1000');
            const data = await response.json();
            nbResults = data.results;
            PPR = PPR.concat(data.data);
            page++;
        } while (nbResults > (10 * page));

        return { PPR: PPR, insee: insee };
    },

    openGeoportailUrbanismeMap: (lngLat) => {
        // url to open
        const url = "https://www.geoportail-urbanisme.gouv.fr/map/#tile=1&lon=" + lngLat.lng + "&lat=" + lngLat.lat + "&zoom=19&mlon=" + lngLat.lng + "&mlat=" + lngLat.lat;

        // open the url in a new tab
        window.open(url, '_blank');

    }
}

export default CadastreServices;